import { IconButton, Menu, MenuItem, TextField } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import "./SimpleAutoComplete.scss";

import { FixMeLater } from "src/types";
import {
    StyledAutoComplete,
    StyledFormControl,
} from "./SimpleAutoComplete.styled";

interface Option {
    label: string;
    value: FixMeLater;
}

interface SimpleAutoCompleteProps {
    data: Option[];
    label: string;
    onSelect: (value: FixMeLater) => void;
    selected: Option["value"];
    size: "small" | "medium" | undefined;
    icon: React.ReactNode;
    ["data-testid"]?: string;
    disableClearButton?: boolean;
}

const SimpleAutoComplete: FC<SimpleAutoCompleteProps> = ({
    data,
    label,
    onSelect,
    selected,
    size,
    icon,
    "data-testid": dataTestId,
    disableClearButton,
}) => {
    const autoCompleteRef = useRef<FixMeLater>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showSelectorIcon, setShowSelectorIcon] = useState(false);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            if (entries && entries.length > 0) {
                const componentWidth = entries[0].contentRect.width;
                setShowSelectorIcon(componentWidth <= 50);
            }
        });
        if (autoCompleteRef.current) {
            observer.observe(
                autoCompleteRef.current.parentElement?.parentElement,
            );
        }
        return () => {
            observer.disconnect();
        };
    }, [autoCompleteRef.current]);

    const handleChange = (_, newValue: unknown | null) => {
        if (newValue) {
            onSelect((newValue as Option).value);
        }
        setAnchorEl(null);
    };

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <StyledFormControl size={size}>
            {!showSelectorIcon ? (
                <StyledAutoComplete
                    ref={autoCompleteRef}
                    options={data}
                    getOptionLabel={(option) => (option as Option).label}
                    onChange={handleChange}
                    value={data.find((item) => item.value === selected) || null}
                    renderInput={(params) => (
                        <TextField {...params} label={label} />
                    )}
                    disableClearable={disableClearButton}
                    data-testid={dataTestId}
                    autoHighlight
                />
            ) : (
                <div className="">
                    <IconButton
                        ref={autoCompleteRef}
                        disableRipple={true}
                        className="selector-icon"
                        onClick={handleOpenMenu}
                    >
                        {icon}
                    </IconButton>
                    <Menu
                        className="auto-complete-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        {data.map((option) => (
                            <MenuItem
                                key={option.value}
                                onClick={(e) => handleChange(e, option)}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
        </StyledFormControl>
    );
};

export default SimpleAutoComplete;
