import NotesIcon from "@mui/icons-material/Notes";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Tooltip } from "@mui/material";
import React, {
    CSSProperties,
    ChangeEvent,
    FC,
    useEffect,
    useRef,
    useState,
} from "react";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import {
    CheckField,
    FixMeLater,
    NoteFieldType,
    NumField,
    ReturnDocument,
    ReturnDocumentNoteState,
    ReturnPage,
    TextField as TextFieldType,
} from "src/types";
import DynamicColors from "src/utils/DynamicColors";
import Formatter, { removeCharacters } from "src/utils/Formatter";
import SpacedText from "../SpacedText/SpacedText";
import "./TextField.scss";

// Define types for props
export interface TextFieldProps {
    scale: number;
    templateField?: FixMeLater;
    value: {
        value: string | number | boolean;
        isOverride: boolean;
        documentField: NumField | TextFieldType | CheckField;
    };
    updateReturnDocument: (
        fieldType: string,
        documentNode: NumField | TextFieldType | CheckField,
        page: ReturnPage,
        overrideValue: string | number | boolean,
    ) => void;
    isLocked?: boolean;
    isPaymentRequest?: boolean;
    returnPage: ReturnPage;
    returnDocument: ReturnDocument;
    id: string;
}

// Helper function to get sanitized value and check for date formatting
function format(templateField, value) {
    // Check if the field is a date field and apply formatting
    if (
        templateField.fieldType === "TextFieldTemplate" &&
        templateField.textFormatType === "FORMAT_DATE"
    ) {
        const dayMonthYear = Formatter.parseDate(value);
        const formattedDateValue = Formatter.format(
            templateField,
            dayMonthYear,
        );
        return formattedDateValue;
    }

    // Apply general formatting for other field types
    const formattedValue = Formatter.format(templateField, value);
    return formattedValue;
}

// Main TextField component
const TextField: FC<TextFieldProps> = ({
    scale,
    templateField,
    value,
    updateReturnDocument,
    isLocked,
    isPaymentRequest,
    returnPage,
    returnDocument,
    id,
}) => {
    // Destructure props for better readability
    const { position, size } = templateField?.screenBox || {};
    const {
        overrideState,
        textFormatType,
        style,
        maxLength,
        isEnabled,
        toolTip,
        tabIndex,
        spacingArray,
        excludedChar,
        hyperlink,
    } = templateField || {};

    const hasSpacingArray = !!(spacingArray && spacingArray.trim() !== "");

    const [formattedInputValue, setFormattedInputValue] = useState<string>(
        format(templateField, value?.value),
    );

    const [notFormattedInputValue, setNotFormattedInputValue] =
        useState<string>(value?.value.toString() ?? "");

    const [inputValue, setInputValue] = useState<string>(
        format(templateField, value?.value),
    );

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );

    const returnNotes: ReturnDocumentNoteState = useAppSelector(
        (state) => state?.[product?.productName]?.value?.returnNotes,
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        // Update inputValue when value.value changes
        setAllInputValues(value?.value.toString() ?? "");
        setInputValue(format(templateField, value?.value.toString()));
    }, [value.value]);

    const [inputType, setInputType] = useState<string>("text");

    const [showSpacedText, setShowSpacedText] =
        useState<boolean>(hasSpacingArray);

    // Ref for input element
    const inputRef = useRef<HTMLInputElement | null>(null);

    const isFocusedFieldNote = id === returnNotes?.focusedFieldNoteId;

    useEffect(() => {
        if (isFocusedFieldNote && !isPaymentRequest) {
            inputRef?.current?.focus();
        }
    }, [returnNotes?.focusedFieldNoteId, returnNotes?.focusedFieldNoteType]);

    const dynamicTextFieldColor = DynamicColors.calculateDynamicTextFieldColor(
        isEnabled,
        overrideState,
        value?.isOverride,
    );
    const dynamicBackgroundColor =
        DynamicColors.calculateDynamicBackgroundColor(isEnabled, overrideState);

    // Dynamic styles for position and size
    const dynamicPositionAndSize: CSSProperties = {
        left: `${position?.left * scale}px`,
        top: `${position?.top * scale}px`,
        height: `${size?.height * scale}px`,
        width: `${size?.width * scale}px`,
    };

    // Dynamic styles for input element
    const dynamicStylesInput: CSSProperties = {
        backgroundColor: dynamicBackgroundColor,
        textAlign: (style?.alignment || "left")?.toLowerCase(),
        fontSize: `${(style?.fontSize || 10) * scale}px`,
        fontWeight: style?.bold ? "bold" : "normal",
        fontStyle: style?.italic ? "italic" : "normal",
        // If spaced text is visible, make the text color transparent
        color: showSpacedText ? "transparent" : dynamicTextFieldColor,
        cursor: isLocked ? "not-allowed" : "",
        border: hyperlink
            ? `3px solid ${DynamicColors.calculateDynamicTextFieldBorderColor(hyperlink)}`
            : isFocusedFieldNote && !isPaymentRequest
              ? "2px solid #000000"
              : "",
    };

    // Dynamic styles for spaced text element
    const dynamicStylesSpacingArray: CSSProperties = {
        height: `${size?.height * scale}px`,
        width: `${size?.width * scale}px`,
        color: dynamicTextFieldColor,
        textAlign: (style?.alignment || "left")?.toLowerCase(),
        fontSize: `${(style?.fontSize || 10) * scale}px`,
        fontWeight: style?.bold ? "bold" : "normal",
        fontStyle: style?.italic ? "italic" : "normal",
    };

    // Handler for spacing array click
    const handleSpacingArrayClick = () => {
        // Hide spaced text and focus on the input when the spaced text is clicked
        setShowSpacedText(false);
        inputRef?.current?.focus();
    };

    // Handler for input focus
    const handleInputFocus = () => {
        setInputValue(notFormattedInputValue);
        // Hide spaced text when the input is focused
        if (hasSpacingArray) {
            setShowSpacedText(false);
        }

        if (textFormatType === "FORMAT_DATE") {
            setInputType("date");
        }

        if (!isPaymentRequest) {
            dispatch(
                GlobalStateActions?.[product?.productName]?.setReturnNotes({
                    ...returnNotes,
                    focusedFieldNotePage: returnPage.attributes.pageOrder,
                    focusedFieldNoteId: id,
                    focusedFieldNoteType: NoteFieldType.TEXT,
                }),
            );
        }
    };

    const setAllInputValues = (newValue: string) => {
        setInputValue(newValue);
        setNotFormattedInputValue(newValue);
        setFormattedInputValue(format(templateField, newValue));
    };

    // Handler for input blurs
    const handleInputBlur = () => {
        if (!isPaymentRequest) {
            dispatch(
                GlobalStateActions?.[product?.productName]?.setReturnNotes({
                    ...returnNotes,
                    focusedFieldNotePage: undefined,
                    focusedFieldNoteId: undefined,
                    focusedFieldNoteType: undefined,
                }),
            );
        }

        // Show spaced text when the input is blurredfi
        if (hasSpacingArray) {
            setShowSpacedText(true);
        }

        setInputType("text");

        const currentValue = value.value;
        const defaultValue = value.documentField.value;
        const newValue: string = notFormattedInputValue;

        // Check if the default value is used
        const isDefaultValueUsed = currentValue === defaultValue;

        // Check if the new value is trying to clear the default value
        const clearingDefaultValue = newValue === "" && isDefaultValueUsed;

        // Check if the new value is empty
        const isNewValueEmpty = newValue === "";

        // Check if the new value is equal to the current value
        const isNewValueEqualToCurrent = newValue === currentValue;

        // Check if the new value is equal to the default value
        const isNewValueEqualToDefault = newValue === defaultValue;

        if (clearingDefaultValue) {
            setAllInputValues(defaultValue.toString());
            setInputValue(format(templateField, defaultValue));
            return;
        }

        if (isNewValueEqualToCurrent) {
            setAllInputValues(currentValue);
            setInputValue(format(templateField, currentValue));
            return;
        }

        // If the new value is different from the default value and not empty, update the document
        if (!isNewValueEqualToDefault && !isNewValueEmpty) {
            updateReturnDocument(
                "textFields",
                value?.documentField,
                returnPage,
                newValue,
            );
            setAllInputValues(newValue);
        } else {
            // If the new value is the same as the default value or empty, reset the field to the default value
            updateReturnDocument(
                "textFields",
                value?.documentField,
                returnPage,
                "",
            );
            setAllInputValues(defaultValue!.toString());
        }

        setInputValue(formattedInputValue);
    };

    // Handler for input change
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setAllInputValues(newValue);
    };

    // Handler for input key press
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            handleInputBlur();
        }
    };

    return (
        <Tooltip title={toolTip} placement="top">
            <div
                className="text-field-container"
                style={dynamicPositionAndSize}
            >
                {/* Input element with dynamic styles */}
                <input
                    className="text-field-input"
                    type={inputType}
                    style={dynamicStylesInput}
                    value={
                        excludedChar && excludedChar.length > 0
                            ? removeCharacters(inputValue, excludedChar)
                            : inputValue
                    }
                    readOnly={isLocked}
                    disabled={!isEnabled}
                    tabIndex={tabIndex}
                    maxLength={maxLength}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    onFocus={handleInputFocus}
                    onKeyDown={handleKeyPress} // Handle key press events
                    ref={inputRef}
                    id={id}
                    data-testid={id}
                    title="Text Field"
                />
                {/* Conditionally render spaced text element */}
                {showSpacedText ? (
                    <SpacedText
                        reversed={false}
                        runsInput={spacingArray}
                        // Exclude characters and apply optional chaining
                        value={
                            excludedChar && excludedChar.length > 0
                                ? removeCharacters(inputValue, excludedChar)
                                : inputValue
                        }
                        scale={scale}
                        // Pass dynamic styles to SpacedText component
                        style={dynamicStylesSpacingArray}
                        onClick={handleSpacingArrayClick}
                    />
                ) : null}

                <div className="icon-container">
                    {hyperlink && (
                        <OpenInNewIcon
                            sx={{
                                color: DynamicColors.calculateDynamicTextFieldBorderColor(
                                    hyperlink,
                                ),
                            }}
                            onClick={() => {
                                alert("hyperlink : " + hyperlink);
                                dispatch(
                                    GlobalStateActions?.[
                                        product?.productName
                                    ]?.setReturnNotes({
                                        newFieldNoteId: id,
                                        newFieldNotePage:
                                            returnPage.attributes.pageOrder,
                                        newFieldNoteType: NoteFieldType.TEXT,
                                        showNotes: true,
                                    }),
                                );
                            }}
                        />
                    )}
                    {templateField.textFormatType !== "FORMAT_DATE" &&
                        !isPaymentRequest && ( // We do not want to show the notes icon for date fields
                            <NotesIcon
                                onClick={() => {
                                    dispatch(
                                        GlobalStateActions?.[
                                            product?.productName
                                        ]?.setReturnNotes({
                                            newFieldNoteId: id,
                                            newFieldNoePage:
                                                returnPage.attributes.pageOrder,
                                            newFieldNoteType:
                                                NoteFieldType.TEXT,
                                            showNotes: true,
                                        }),
                                    );
                                }}
                            />
                        )}
                </div>
            </div>
        </Tooltip>
    );
};

export default TextField;
