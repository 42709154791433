import {
    Company,
    NoteFieldType,
    Product,
    ReturnNode,
    ReturnPage,
} from "src/types";


export const generateUniqueId = (
    product: Product,
    company: Company,
    returnNode: ReturnNode,
    returnPage?: ReturnPage,
    fieldType?: NoteFieldType,
    fieldId?: string,
    suffix?: string,
): string => {
    const parts = [
        `Y${product.taxYear}`,
        `C${company.id}`,
        `R${returnNode.id}`,
    ];

    if (returnPage) {
        parts.push(`P${returnPage.attributes.pageOrder}`);
    }

    if (fieldType) {
        parts.push(`T${fieldType}`);
    }

    if (fieldId) {
        parts.push(`F${fieldId}`);
    }

    if (suffix) {
        parts.push(suffix);
    }

    return parts.join(':');
};
