import React, { FC, useRef, useState } from "react";
import { ReturnDocumentService } from "src/services";
import { FixMeLater, QueryParams } from "src/types";
import "./ESignatureModal.scss";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { FormControlLabel, Radio, Typography } from "@mui/material";
import { HttpError } from "src/utils/HttpError";
import { StyledClearButton, StyledContainedButton, StyledRadioGroup, StyledSignatureInput, StyledTextButton } from "./ESignatureModal.styled";
import ReactSignatureCanvas from "react-signature-canvas";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

const RESERVED_SPACE = 200;
const FONT_SIZE = "100px";
const X_CANVAS_POSITION = 10;
const Y_CANVAS_POSITION = 100;

interface ESignatureModalProps {
    onClose: () => void;
    handleSnackbar: (message: string | string[], severity: string, autoHideDuration?: number) => void;
    returnInput: QueryParams,
}

const ESignatureModal: FC<ESignatureModalProps> = ({onClose, handleSnackbar, returnInput}) => {
    const returnDocumentService = ReturnDocumentService.getInstance();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [textSignature, setTextSignature] = useState<string>("");
    const [signatureFont, setSignatureFont] = useState<string>("Arabella");

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );

    const municipalState: String = useAppSelector(
        (state) => state?.Municipal?.value.selectedState,
    );
    const municipalQuarter: String = useAppSelector(
        (state) => state?.Municipal?.value.selectedQuarter,
    );

    const textFieldRef = useRef<HTMLInputElement>(null);
    const textCanvasRef = useRef<ReactSignatureCanvas>(null);

    const dispatch = useAppDispatch();

    const uploadSignature = async (blob: Blob) => {
        const file = new File([blob], "Signature")
        const formData = new FormData();
        formData.append("file", file, file.name)
        const attachmentMetadata = new Blob(
            [
                JSON.stringify(
                    returnInput
                ),
            ],
            { type: "application/json" },
        );

        formData.append("returnInput", attachmentMetadata);

        try {
            await returnDocumentService.signReturnDocument(formData);
            dispatch(GlobalStateActions[product?.productName].setToogleFetchSignature());
        } catch (error: FixMeLater) {
            if (error instanceof HttpError) {
                handleSnackbar(JSON.parse(error?.body)?.message, "error");
            } else {
                handleSnackbar("Error uploading signature", "error");
            }
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    const fillTextCanvas = () => {
        setIsLoading(true);
        
        const canvas = textCanvasRef.current;
        const context = canvas?.getCanvas()?.getContext("2d");

        if (context) {
            // setting font and size for correct measuring text
            context.font = `${FONT_SIZE} ${signatureFont}`;
            context.canvas.width = context.measureText(textSignature).width + RESERVED_SPACE;
            context.canvas.height = RESERVED_SPACE;
            // need to set font and size again after resizing
            context.font = `${FONT_SIZE} ${signatureFont}`;
            context.fillText(textSignature, X_CANVAS_POSITION, Y_CANVAS_POSITION);
        } else {
            handleSnackbar("Error getting signature context. Please try again.", "error");
            setIsLoading(false);
        }

        canvas?.getTrimmedCanvas()?.toBlob((blob: Blob | null) => {
            if (blob) {
                uploadSignature(blob);
            } else {
                handleSnackbar("Error creating signature", "error");
                setIsLoading(false);
            }
        }, "image/png");
    };

    const clearSignatureInput = () => {
        if (textFieldRef.current) {
            textFieldRef.current.value = "";
            setTextSignature("");
        }
    }

    return (
        <div className="e-signature-modal-container">
            <StyledSignatureInput 
                placeholder="Signature"
                ref={textFieldRef}
                disabled={isLoading}
                $signatureFont={signatureFont}
                onChange={(e) => setTextSignature(e?.target?.value)}
            />
            <StyledClearButton
                disabled={isLoading}
                disableRipple
                $isTextSignatureFilled={!!textSignature?.length}
                onClick={clearSignatureInput}
            >
                { textSignature?.length ? "Clear signature" : "Sign here" }
            </StyledClearButton>
            <ReactSignatureCanvas
                ref={textCanvasRef}
                canvasProps={{ style: { display: "none" }}}
            />
            <StyledRadioGroup
                value={signatureFont}
                onChange={(e) => setSignatureFont(e?.target?.value)}
            >
                <FormControlLabel
                    value={"Arabella"}
                    control={<Radio size="small"/>}
                    label={textSignature || "Signature"}
                    disabled={isLoading}
                    sx={{ "& .MuiFormControlLabel-label": { fontFamily: "Arabella" } }}
                />
                <FormControlLabel
                    value={"GreatVibes-Regular"}
                    control={<Radio size="small"/>}
                    label={textSignature || "Signature"}
                    disabled={isLoading}
                    sx={{ "& .MuiFormControlLabel-label": { fontFamily: "GreatVibes-Regular" } }}
                />
                <FormControlLabel
                    value={"Montez-Regular"}
                    control={<Radio size="small"/>}
                    label={textSignature || "Signature"}
                    disabled={isLoading}
                    sx={{ "& .MuiFormControlLabel-label": { fontFamily: "Montez-Regular" } }}
                />
                <FormControlLabel
                    value={"Sacramento-Regular"}
                    control={<Radio size="small"/>}
                    label={textSignature || "Signature"}
                    disabled={isLoading}
                    sx={{ "& .MuiFormControlLabel-label": { fontFamily: "Sacramento-Regular" } }}
                />
            </StyledRadioGroup>
            <Typography>Signature will appear on all other returns for {municipalState}, {municipalQuarter}, {product?.taxYear.toString()}</Typography>
            <div className="e-signature-modal-actions">
                <StyledContainedButton
                    loading={isLoading}
                    variant="contained"
                    disabled={!textSignature?.length}
                    onClick={fillTextCanvas}
                >
                    Sign
                </StyledContainedButton>
                <StyledTextButton
                    disabled={isLoading}
                    onClick={onClose}
                >
                    Cancel
                </StyledTextButton>
            </div>
        </div>
    );
};

export default ESignatureModal;
