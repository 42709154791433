import styled from "styled-components";
import colors from "src/styles/colors.scss";
import { ButtonBase, RadioGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface StyledClearButtonProps {
    $isTextSignatureFilled: boolean;
}

interface StyledSignatureInputProps {
    $signatureFont: string;
}

export const StyledRadioGroup = styled(RadioGroup)`
    margin: 16px 0;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;

    .MuiFormControlLabel-root {
        margin: 0;
        border-bottom: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
    }
    
    .MuiFormControlLabel-label {
        font-size: 20px;
        margin-top: 3px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: ${colors.primaryColor};
    }

    .MuiRadio-colorPrimary {
        color: ${colors.primaryColor};
    }

    .Mui-disabled {
        color: ${colors.grey};
    }
`;

export const StyledSignatureInput = styled.input<StyledSignatureInputProps>`
    height: 46px;
    border: 2px dashed #e8e8e8;
    border-radius: 4px;
    font-size: 32px;
    color: ${colors.primaryColor};
    font-family: ${(props) => props.$signatureFont};
    text-align: center;
    padding: 14px;
    outline: none;
    margin: 12px 0;

    &::placeholder {
        color: ${colors.grey};
    }
`;

export const StyledContainedButton = styled(LoadingButton)`
    color: ${colors.white};
    background-color: ${colors.primaryColor};

    &: hover {
        background-color:  ${colors.primaryColor};
    }
`;

export const StyledTextButton = styled(LoadingButton)`
    color: ${colors.primaryColor};
    background-color: ${colors.white};
`;

export const StyledClearButton = styled(ButtonBase)<StyledClearButtonProps>`
    width: 120px;
    margin: auto; 
    font-weight: bold; 
    color: ${(props) => props.$isTextSignatureFilled ? colors.primaryColor : colors.grey };
    padding: 5px;
    font-size: 12px;
    border-radius: 4px;
    cursor: ${(props) => props.$isTextSignatureFilled ? "pointer" : "default" };
`;