import { gql } from "graphql-request";

export const returnTreeQuery = gql`
    query ReturnTree($treeInput: UITreeInput!) {
        tree(treeInput: $treeInput) {
            folderNodes {
                id
                attributes {
                    displayName
                    displayOrder
                    abbreviation
                    releaseNoteChangeIndicator
                    isRetalNeeded
                    releaseNoteId
                }
                returnNodes {
                    id
                    displayName
                    isDomestic
                    isForeign
                    displayOrder
                    resourceId
                    isHidden
                    municipalId
                    returnInstructionPdf
                    scheduleType
                    returnStatus
                    formStatus
                    isRetalReturn
                    activateRetal
                    retalFolderId
                    isActive
                    isLocked
                    isReady
                    filingType
                    efile
                    efileEmail
                }
            }
        }
    }
`;

export const returnCompaniesTreeQuery = gql`
    query ReturnTree($compTreeInput: UICompTreeInput!) {
        treeCompanies(compTreeInput: $compTreeInput) {
            folderNodes {
                id
                attributes {
                    displayName
                    displayOrder
                    abbreviation
                    releaseNoteChangeIndicator
                    isRetalNeeded
                    releaseNoteId
                }
                returnNodes {
                    id
                    displayName
                    isDomestic
                    isForeign
                    displayOrder
                    resourceId
                    isHidden
                    municipalId
                    returnInstructionPdf
                    scheduleType
                    returnStatus
                    formStatus
                    isRetalReturn
                    activateRetal
                    retalFolderId
                    isActive
                    isLocked
                    isReady
                    filingType
                    efile
                    efileEmail
                }
            }
        }
    }
`;

export const returnTemplateQuery = gql`
    query ReturnTemplate($returnInput: ReturnInput!) {
        returnTemplate(input: $returnInput) {
            displayName
            properties {
                key
                value
            }
            returnPages {
                id
                attributes {
                    caption
                    displayOrder
                    pageOrder
                    isVisible
                    pageInstance
                    pageType
                    templateId
                }
            }
            fieldTemplates {
                templateId: id
                textFieldTemplates {
                    ...screenBox
                    toolTip
                    hyperlink
                    tabIndex
                    maxLength
                    textFormatType
                    format
                    isMultiline
                    style {
                        alignment
                        fontSize
                        bold
                        italic
                    }
                    overrideState
                    spacingArray
                    excludedChar
                }
                signatureFieldTemplates {
                    ...screenBox
                    scale
                }
                numberFieldTemplates {
                    ...screenBox
                    toolTip
                    hyperlink
                    tabIndex
                    maxLength
                    numericFormatType
                    format
                    excludedChar
                    decimalPrecision
                    style {
                        alignment
                        fontSize
                        bold
                        italic
                    }
                    overrideState
                    spacingArray
                    excludedChar
                }
                labelFieldTemplates {
                    ...screenBox
                    textValue
                    style {
                        alignment
                        fontSize
                        bold
                        italic
                    }
                }
                comboBoxFieldTemplates {
                    ...screenBox
                    toolTip
                    tabIndex
                    options
                    style {
                        alignment
                        fontSize
                        bold
                        italic
                    }
                    overrideState
                }
                checkFieldTemplates {
                    ...screenBox
                    toolTip
                    tabIndex
                    checkFormatType
                    overrideState
                }
            }
            filingAddresses {
                filingAddressRows {
                    filingAddresses {
                        addressOptionDescription
                        addressOptionNumber
                        city
                        contactEmail
                        contactName
                        contactPhone
                        payee
                        state
                        streetOne
                        streetTwo
                        website
                        zip
                    }
                    hasPaymentRequest
                }
            }
        }
    }

    fragment screenBox on FieldTemplate {
        id
        fieldType: __typename
        isEnabled
        isVisible
        screenBox(displayUnit: PIXELS) {
            position {
                left
                top
            }
            size {
                width
                height
            }
        }
    }
`;

export const scheduleTemplateQuery = gql`
    query ScheduleTemplateDTO(
        $scheduleInput: ScheduleInput!
        $queryJurisdiction: ID!
    ) {
        scheduleTemplateDTO(
            scheduleInput: $scheduleInput
            queryJurisdiction: $queryJurisdiction
        ) {
            columnTemplateDTOs {
                columnTemplate {
                    height
                    id
                    label
                    name
                    width
                    overrideState
                    cellType
                    alignment
                }
                index
            }
            rowDTOs {
                rowId
                currentIndex
                cellGroups {
                    cells {
                        alignment
                        cellType
                        column
                        scheduleId
                        editable
                        overrideState
                        format
                        textHyperlinkType
                        ov
                        v
                        formatType
                        decimalPrecision
                        excludedCharacters
                    }
                }
            }
            scheduleName
            totalRowsNum
            displayTotalRow
            queryJurisdiction
        }
    }
`;

export const scheduleOnlyFieldsQuery = gql`
    query ScheduleTemplateDTO($scheduleInput: ScheduleInput!) {
        scheduleTemplateDTO(scheduleInput: $scheduleInput) {
            columnTemplateDTOs {
                columnTemplate {
                    id
                    name
                }
                index
            }
        }
    }
`;
