import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import PdfControls from "src/components/PdfControls/PdfControls";
import { getOverlayFields } from "src/components/PdfViewer/PdfViewer.util.overlayFields";
import PdfViewerPages from "src/components/PdfViewerPages/PdfViewerPages";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { GraphqlService, ReturnDocumentService } from "src/services";
import { returnTemplateQuery } from "src/services/GQLQueries";
import { getModuleId } from "src/services/Utility";
import { ScrollSyncPane } from "react-scroll-sync";
import {
    CheckField,
    Company,
    FixMeLater,
    FolderNode,
    NumField,
    Product,
    PushReturnDocument,
    QueryParams,
    ReturnDocument,
    ReturnDocumentNoteState,
    ReturnNode,
    ReturnPage,
    ReturnTemplate,
    State,
    TextField,
    UserOptions,
    ValidationResponse,
} from "src/types";
import { generateUniqueId, getQueryParams } from "src/utils";
import CustomSnackbar from "../../CustomSnackbar/CustomSnackbar";
import {
    fetchDocument,
    fetchOnlyDocument,
    fetchPdf,
    handleResponse,
} from "../PdfViewer.util";
import "./PdfRender.scss";
import { DEFAULT_ZOOM_SCALE } from "src/constants";
import ScrollableNoteList from "src/components/ScrollableNoteList/ScrollableNoteList";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

export interface PdfRenderProps {
    documentKey: string;
    returnDocument: ReturnDocument | undefined;
    setReturnDocument: React.Dispatch<
        React.SetStateAction<ReturnDocument | undefined>
    >;
    returnTemplate: ReturnTemplate | undefined;
    setReturnTemplate: React.Dispatch<
        React.SetStateAction<ReturnTemplate | undefined>
    >;
    setValidationResponse: React.Dispatch<
        React.SetStateAction<ValidationResponse | null>
    >;
    setPendingChanges: React.Dispatch<React.SetStateAction<boolean>>;
    isPaymentRequest?: boolean;
    product: Product;
    company: Company;
    folderNode: FolderNode;
    returnNode: ReturnNode;
    isActive: boolean;
    isSplitedScreen: boolean;
}

const PdfRender: React.FC<PdfRenderProps> = ({
    documentKey,
    returnDocument,
    setReturnDocument,
    returnTemplate,
    setReturnTemplate,
    setValidationResponse,
    setPendingChanges,
    isPaymentRequest = false,
    product,
    company,
    folderNode,
    returnNode,
    isActive,
    isSplitedScreen,
}) => {
    const returnDocumentService = ReturnDocumentService.getInstance();
    const graphqlService = GraphqlService.getInstance();
    const dispatch = useAppDispatch();

    const [error, setError] = useState<FixMeLater>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");
    const [pagesData, setPagesData] = useState<
        {
            page: ReturnPage;
            overlayFields?: React.ReactNode[];
        }[]
    >([]);
    const [pdfFile, setPdfFile] = useState<Blob | null>(null);

    const userOptions: UserOptions | undefined = useAppSelector(
        (state) => state.UserOptions.value,
    );
    const scale = Number(
        userOptions.globalPreferences?.zoom || DEFAULT_ZOOM_SCALE,
    );

    const [selectedReturnPage, setSelectedReturnPage] = useState<ReturnPage>();

    const municipalState: String = useAppSelector(
        (state) => state?.Municipal?.value.selectedState,
    );

    const dropDownState: boolean = useAppSelector(
        (state) => state?.States?.dropDownState,
    );
    const selectedState: State = useSelector(
        (state) => state?.[product?.productName]?.value?.state,
    );

    const returnNotes: ReturnDocumentNoteState = useAppSelector(
        (state) => state?.[product?.productName]?.value.returnNotes,
    );

    const showNotes: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value.returnNotes?.showNotes,
    );

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs,
    )?.tabsProperties;

    const returnDocumentId = {
        productId: product.productId.toString(),
        taxYearId: product.taxYear.toString(),
        folderId: dropDownState
            ? selectedState?.id.toString()
            : folderNode?.id.toString(),
        moduleId: getModuleId(product, company ? company : {}, municipalState),
        returnId: returnNode.id.toString(),
        retalFolderId: returnNode.retalFolderId?.toString()!!,
        paymentRequestFormId: isPaymentRequest
            ? "1171"
            : returnNode?.id.toString() ?? null,
    };

    const requestFectchReturnDocument: QueryParams = getQueryParams({
        ...returnDocumentId,
        companyId: company?.id,
        taxYear: returnDocumentId.taxYearId,
    });

    const requestFectchPdf: QueryParams = getQueryParams({
        ...returnDocumentId,
        returnId: isPaymentRequest ? "1171" : returnDocumentId.returnId,
        retalFolderId: isPaymentRequest
            ? "108"
            : returnDocumentId.retalFolderId,
    });

    const isManualSave =
        returnTemplate?.properties?.find(
            (property) => property?.key === "SaveType",
        )?.value === "MANUAL" || false;

    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        setIsLoading(true);
        fetchPdf(requestFectchPdf, setPdfFile);
        fetchReturnTemplate(returnDocumentId, setReturnTemplate);
    }, []);

    useEffect(() => {
        if (isActive && isPaymentRequest) {
            fetchOnlyDocument(requestFectchReturnDocument, setReturnDocument);
        }
    }, [isActive, tabsProperties[documentKey]?.isLocked]);

    useEffect(() => {
        if (pdfFile && returnDocument && returnTemplate) {
            setIsLoading(false);
        }
    }, [pdfFile, returnDocument, returnTemplate]);

    useEffect(() => {
        const el = document.getElementById(
            generateUniqueId(
                product,
                company,
                returnNode,
                selectedReturnPage,
                undefined,
                undefined,
                `${isSplitedScreen ? "SS" : ""}`,
            ),
        );
        if (el) {
            el.scrollIntoView({ behavior: "smooth" });
        }
    }, [selectedReturnPage]);

    useEffect(() => {
        if (returnTemplate) {
            setPagesData(getAllOverlayFields());
        }
    }, [scale, returnDocument, returnTemplate]);

    const getAllOverlayFields = () => {
        if (returnTemplate?.returnPages) {
            return returnTemplate?.returnPages
                .sort((a, b) => {
                    return a.attributes.pageOrder - b.attributes.pageOrder;
                })
                .map((returnPage) => {
                    return {
                        page: returnPage,
                        overlayFields: getOverlayFields(
                            returnTemplate?.fieldTemplates,
                            returnPage,
                            scale,
                            returnDocument,
                            updateReturnDocument,
                            isPaymentRequest,
                            company,
                            product,
                            returnNode,
                            requestFectchReturnDocument,
                        ),
                    };
                });
        } else {
            return [];
        }
    };

    const fetchReturnTemplate = async (params, setReturnTemplate) => {
        const returnInput = { ...params };
        try {
            const fetchedReturnTemplate = await graphqlService.fetchData<{
                returnTemplate: ReturnTemplate;
            }>(returnTemplateQuery, { returnInput });

            setReturnTemplate(fetchedReturnTemplate?.returnTemplate);

            setSelectedReturnPage(
                fetchedReturnTemplate?.returnTemplate?.returnPages[0],
            );
        } catch (error) {
            console.error("Error fetching return template:", error);
        }
    };

    const pushDocument = async (
        updatedReturnDocument,
        showMessages: boolean = true,
    ) => {
        updatedReturnDocument.returnVersion++;

        // Push the updated return document to the service
        try {
            const response: PushReturnDocument =
                await returnDocumentService.pushReturnDocument(
                    updatedReturnDocument,
                );
            // Update the local state based on the response
            const updatedDocument = handleResponse(
                updatedReturnDocument,
                response,
            );

            setValidationResponse(response.validationResponse);
            setReturnDocument(updatedDocument);

            // Display success message
            if (showMessages) {
                handleSnackbar("saved", "success");
            }
        } catch (error: FixMeLater) {
            const message = JSON.parse(error?.body)?.message;

            // Handle and display error message
            if (message === "Attempted to push when return is out of date") {
                handleSnackbar(
                    `Not saved.\nThe return has been updated outside of this session. please try again Error: ${error.message}`,
                    "error",
                );
                setIsLoading(true);
                await fetchDocument(
                    requestFectchReturnDocument,
                    setReturnDocument,
                    setValidationResponse,
                );
                setIsLoading(false);
            } else if (
                message === "Attempted to update return that was already locked"
            ) {
                handleSnackbar(message, "error");

                setIsLoading(true);
                await fetchDocument(
                    requestFectchReturnDocument,
                    setReturnDocument,
                    setValidationResponse,
                );
                setIsLoading(false);
            } else {
                // For other errors
                handleSnackbar(
                    `There has been an error saving data. ${error}`,
                    "error",
                );
            }
        }
    };

    // Function to update returnDocument and trigger the overlayFields update
    function updateReturnDocument(
        fieldType: string,
        documentNode: NumField | TextField | CheckField,
        returnPage: ReturnPage,
        overrideValue: string | number | boolean,
    ): void {

        // Find the corresponding page in returnDocument
        const returnDocumentPage = returnDocument?.pages?.find(
            (x) => x.pageOrder === returnPage?.attributes.pageOrder,
        );

        if (returnDocumentPage) {
            // Update the specific field in the page
            const updatedReturnDocumentPage = {
                ...returnDocumentPage,
                [fieldType]: returnDocumentPage[fieldType].map((x) =>
                    x.id === documentNode.id
                        ? { ...x, overrideValue: overrideValue }
                        : x,
                ),
            };

            // Update the page in the returnDocument
            const updatedReturnDocument: ReturnDocument = {
                ...returnDocument,
                pages: returnDocument?.pages?.map((page) =>
                    page.pageOrder === returnPage?.attributes.pageOrder
                        ? updatedReturnDocumentPage
                        : page,
                ),
            } as ReturnDocument;

            setReturnDocument({ ...updatedReturnDocument });


            if (!isManualSave) {
                pushDocument({ ...updatedReturnDocument });
            } else {
                setPendingChanges(true);
                dispatch(GlobalStateActions.setPendingChange(documentKey));
            }
        }
    }

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching the pdf: ${error.toString()}`}
            />
        );

    return (
        <div className="pdf-renderer-container" data-testid="pdf-render">
            <div className="pdf-pages">
                <PdfViewerPages
                    returnTemplate={returnTemplate}
                    selectedReturnPage={selectedReturnPage}
                    setSelectedReturnPage={setSelectedReturnPage}
                />
            </div>
            <ScrollSyncPane>
                <div className="pdf-and-notes-container">
                    <div className="pdf-viewer">
                        <Document file={pdfFile}>
                            {pagesData.map((data) => {
                                return (
                                    <div
                                        key={generateUniqueId(
                                            product,
                                            company,
                                            returnNode,
                                            data.page,
                                            undefined,
                                            undefined,
                                            `${isSplitedScreen ? "SS" : ""}-Key`,
                                        )}
                                        id={generateUniqueId(
                                            product,
                                            company,
                                            returnNode,
                                            data.page,
                                            undefined,
                                            undefined,
                                            `${isSplitedScreen ? "SS" : ""}`,
                                        )}
                                    >
                                        <Page
                                            className={"pdf-viewer-page"}
                                            pageNumber={
                                                data.page.attributes.pageOrder +
                                                1
                                            }
                                            scale={scale}
                                        >
                                            {data.overlayFields}
                                        </Page>
                                    </div>
                                );
                            })}
                        </Document>
                        <Backdrop
                            sx={{ zIndex: 19, position: "absolute" }}
                            open={!error && isLoading}
                            onClick={() => setIsLoading(false)}
                        >
                            <div className="loader-section">
                                <span className="loader-text">Loading...</span>
                                <CircularProgress sx={{ color: "white" }} />
                            </div>
                        </Backdrop>
                    </div>

                    {showNotes &&
                        !returnDocument?.displayName?.includes(
                            "Payment Request Form",
                        ) && (
                            <div className="pdf-notes-viewer">
                                <ScrollableNoteList
                                    noteData={returnDocument?.returnNotes || []}
                                    returnDocument={returnDocument}
                                    setReturnDocument={setReturnDocument}
                                    product={product}
                                    company={company}
                                    folderNode={folderNode}
                                    returnNode={returnNode}
                                    municipalState={municipalState}
                                    returnNotes={returnNotes}
                                />
                            </div>
                        )}
                </div>
            </ScrollSyncPane>

            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </div>
    );
};

export default PdfRender;
